import Cookies from "js-cookie";

export default {
  set(key: string, value: string, opts: Cookies.CookieAttributes | undefined) {
    Cookies.set(key, value, opts);
  },
  setNoPref(
    key: string,
    value: string,
    opts: Cookies.CookieAttributes | undefined
  ) {
    Cookies.set(key, value, opts);
  },
  get(key: string): string | undefined {
    return Cookies.get(key);
  },
  remove(key: string): void {
    Cookies.remove(key);
  },
};
