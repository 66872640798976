import { userLogin, getUser } from "@/api/login";
import { LoginForm } from "@/views/login/login";
import {getToken,setToken} from '@/utils'

export interface UserStore {
  userInfo: any;
  token: string | undefined;
}

const userStore = {
  namespace: true,
  state(): UserStore {
    return {
      userInfo: undefined,
      token: getToken(),
    };
  },
  getters: {
    area(state: { userInfo: { area: any; }; }) {
      return state.userInfo?.area
    },
    isRegionalHead(state: { userInfo: { roleId: number; }; }) {
      return state.userInfo?.roleId == 1
    }
  },
  mutations: {
    setUserInfo(state: UserStore, userInfo: any): void {
      state.userInfo = userInfo;
    },
    setToken(state: UserStore, token: string): void {
      state.token = token;
      setToken(token)
    },
  },
  actions: {
    Login({ commit }: any, loginForm: unknown): Promise<unknown> {
      return new Promise((resolve, reject) => {
        userLogin(loginForm as LoginForm).then((res: any) => {
          if(res.code === 200) {
            commit('setUserInfo', null)
            commit('setToken', res.data)
          }
          resolve(res)
        })
      })
    },
    GetUserInfo({commit}: any): Promise<unknown> {
      return new Promise((resolve, reject) => {
        getUser().then((res: any) => {
          if(res.code === 200) {
            commit('setUserInfo', res.data)
          }
          resolve(res)
        })
      })
    },
    Layout(): void {
      console.log("Layout");
    },
  },
};

export default userStore;
