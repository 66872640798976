import axios from "@/axios-instance";
import { LoginForm } from "@/views/login/login";

export function userLogin(postData: LoginForm): Promise<any> {
  return axios.post("/app/login/login", postData);
}

export function getUser(): Promise<any> {
  return axios.get("/app/common/getUserInfo");
}
