import axios from "axios";
import { getToken } from "@/utils";
import store from "@/store";
import router from "@/router";
console.log(router);
const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_API,
  timeout: 120000 // 请求超时时间
});
Axios.interceptors.request.use(config => {
  if (getToken()) {
    config.headers["token"] = getToken();
  }
  return config;
});
Axios.interceptors.response.use(response => {
  // console.log(response, 'response')
  if (response.data.code === 200 || response.data.code === 0) {
    return response.data;
  }
  if (response.data.code === 401) {
    store.commit("setUserInfo", undefined);
    store.commit("setToken", undefined);
    router.replace({
      name: "login"
    });
  }
  if (response.data.code === 403) {
    showNotify({
      type: "warning",
      message: `${response.data.msg}`,
      duration: 3000,
      position: "top"
    });
    return false;
  }
  errorInfo(response.data.msg);
  return response.data;
}, error => {
  // console.log(error, 'error')
  if (error.message) {
    errorInfo(error.message);
  } else {
    errorInfo(error.response.statusText || error.response.data.msg);
  }
  return error;
});
function errorInfo(msg) {
  showNotify({
    type: "danger",
    message: `系统异常：${msg}`,
    duration: 3000,
    position: "top"
  });
}
export default Axios;