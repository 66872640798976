import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/home.vue"),
  },
  {
    path: "/sc-enter/add",
    name: "scAdd",
    component: () => import("@/views/sc-enter/add-form.vue"),
  },
  {
    path: "/sg-enter/add",
    name: "sgAdd",
    component: () => import("@/views/sg-enter/add-form.vue"),
  },
  {
    path: "/copartner/add",
    name: "copartnerAdd",
    component: () => import("@/views/copartner/add-form.vue"),
  },
  {
    path: "/customer/list",
    name: "customerList",
    component: () => import("@/views/customer/customer-list.vue"),
  },
  {
    path: "/visit-log/list",
    name: "visitLogList",
    component: () => import("@/views/customer/visit-log-list.vue"),
  },
  {
    path: "/visit-log/add",
    name: "visitLogAdd",
    component: () => import("@/views/customer/add-form.vue"),
  },
  {
    path: "/customer/add",
    name: "customerAdd",
    component: () => import("@/views/customer/add-form.vue"),
  },
  {
    path: "/visit-log-day/list",
    name: "visitLogDay",
    component: () => import("@/views/visit-log-day/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, form, next) => {
  const store = useStore();
  if (to.name === "login") {
    next();
  } else {
    if (store.state.userStore.userInfo) {
      next();
    } else {
      const res = await store.dispatch("GetUserInfo");
      if (res.code === 200) {
        next();
      } else {
        next({ name: "login" });
      }
    }
  }
});

export default router;
